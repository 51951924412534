import { removeCookieTokenOraculo } from '_core/modules/components/ssoOraculo/serviceOraculo';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { editProfile, editUser, getUserByToken } from '../crud/auth.crud';
import { getTokenExpiration, guidGenerator, removeAllStorage } from '../utils/utils';
import { PERSIST_AUTH, REDUCER_AUTH } from './conf';
import { actions as usersActions } from './users.duck';

export const typesRoles = {
  STUDENT: 'R01',
  TEACHER: 'R02',
  SCHOOL_ADMIN: 'R03',
  EDITORIAL_ADMIN: 'R04',
  HELPDESK: 'R05',
};

export const actionTypes = {
  Login: 'auth/LOGIN',
  LoginOraculo: 'auth/LOGIN_ORACULO',
  LoginSSO: 'auth/LOGIN_SSO',
  Logout: 'auth/LOGOUT',
  Register: 'auth/REGISTER',
  UserRequested: 'auth/REQUEST_USER',
  UserLoaded: 'auth/USER_LOADED',
  UserEdit: 'auth/EDIT_USER',
  UserEditReducer: 'auth/EDIT_USER_REDUCER',
  UserEditProfile: 'auth/EDIT_PROFILE',
  UserEditProfileSuccess: 'auth/EDIT_PROFILE_SUCCESS',
  UserEditProfileError: 'auth/EDIT_PROFILE_ERROR',
  EditAvatar: 'auth/EDIT_AVATAR',
  ResetError: 'auth/RESET_ERROR',
  UpdateLicense: 'auth/UPDATE_LICENSE',
  UpdateChangePassword: 'auth/UPDATE_CHANGE_PASSWORD',
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  tokenExpiration: null,
  license: null,
  error: null,
  oauth_accounts: [],
  tokenId: null,
};

export const reducer = persistReducer(
  { storage, key: PERSIST_AUTH, whitelist: ['user', 'authToken', 'tokenExpiration', 'license', 'oauth_accounts', 'tokenId', 'changePassword'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login:
      case actionTypes.LoginSSO: {
        const { authToken } = action.payload;

        return {
          ...state,
          authToken: authToken.token,
          tokenExpiration: getTokenExpiration(authToken.token),
          oauth_accounts: authToken.oauth_accounts,
          user: undefined,
          license: authToken.license,
          changePassword: authToken.changePassword,
        };
      }

      case actionTypes.LoginOraculo:
        return { ...state, tokenId: action.payload };

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // routerHelpers.forgotLastLocation();

        removeAllStorage();
        removeCookieTokenOraculo();
        if (document.cookie.indexOf('PHPSESSID') !== -1) document.cookie = 'PHPSESSID=' + guidGenerator() + '; Path=/;';
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user: user.data };
      }

      case actionTypes.UserEditReducer: {
        const { user } = action.payload;

        return { ...state, user: { ...state.user, email: user.email, name: user.name, lastname: user.lastname } };
      }
      case actionTypes.UserEditProfile: {
        return { ...state, user: { ...state.user }, error: null };
      }

      case actionTypes.UserEditProfileSuccess: {
        const { user } = action.payload;
        return { ...state, user: { ...state.user, ...user }, error: false };
      }

      case actionTypes.editProfileError: {
        return { ...state, user: { ...state.user, ...action.payload }, error: false };
      }

      case actionTypes.EditAvatar: {
        const { avatar } = action.payload;
        return { ...state, user: { ...state.user, avatar }, error: null };
      }

      case actionTypes.ResetError: {
        return { ...state, error: null };
      }

      case actionTypes.UpdateChangePassword: {
        const { changePassword } = action.payload;
        return { ...state, changePassword: changePassword };
      }

      case actionTypes.UpdateLicense: {
        const license = action.payload;
        return { ...state, license: license };
      }

      default:
        return state;
    }
  }
);

export const selectors = {
  getUser: (state) => {
    return state[REDUCER_AUTH] ? state[REDUCER_AUTH].user : null;
  },
  getUserGuid: (state) => {
    return state[REDUCER_AUTH] ? state[REDUCER_AUTH].user.guid : null;
  },
  getUserRoleGuid: (state) => {
    const user = selectors.getUser(state);
    return user ? user.role_guid : null;
  },
  getAuthToken: (state) => {
    return state[REDUCER_AUTH] ? state[REDUCER_AUTH].authToken : null;
  },
  getError: (state) => {
    return state[REDUCER_AUTH] ? state[REDUCER_AUTH].error : null;
  },
  getLicense: (state) => {
    return state[REDUCER_AUTH] ? state[REDUCER_AUTH].license : null;
  },
  getOauthAccounts: (state) => {
    return state[REDUCER_AUTH] ? state[REDUCER_AUTH].oauth_accounts : [];
  },
  getOraculoToken: (state) => {
    return state[REDUCER_AUTH] ? state[REDUCER_AUTH].tokenId : null;
  },
};

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  loginOraculo: (token) => ({ type: actionTypes.LoginOraculo, payload: { token } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  /**
   * Accion de logout
   * @param reducersNoEmpty Listado de reducer que cuando escuchen la accion de  actionTypes.Logout no queremos que vuelvan a su estado inicial
   * // TODO: en un futuro preparar mas ficheros .duck que escuchan la accion actionTypes.Logout para que si viene en el array de reducersNoEmpty su reducer no lo limpie. Ahora solo lo he adaptado para los necesarios hasta ahora.
   */
  logout: (reducersNoEmpty = []) => ({ type: actionTypes.Logout, payload: { reducersNoEmpty } }),
  requestUser: (user) => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  editUser: (user) => ({ type: actionTypes.UserEdit, payload: { user } }),
  editUserReducer: (user) => ({ type: actionTypes.UserEditReducer, payload: { user } }),
  editProfile: (user) => ({ type: actionTypes.UserEditProfile, payload: { user } }),
  editProfileSuccess: (user) => ({ type: actionTypes.UserEditProfileSuccess, payload: { user } }),
  editProfileError: () => ({ type: actionTypes.UserEditProfileError, payload: {} }),
  editAvatar: (avatar) => ({ type: actionTypes.EditAvatar, payload: { avatar } }),
  resetError: () => ({ type: actionTypes.ResetError, payload: {} }),
  updateLicense: (license) => ({ type: actionTypes.UpdateLicense, payload: license }),
  updateChangePassword: (changePassword) => ({ type: actionTypes.UpdateChangePassword, payload: changePassword }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.LoginSSO, function* loginSSOSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.UserLoaded, function* userLoadedSaga(action) {
    const { user } = action.payload;
    const schoolGuid = user?.data?.schools[0]?.guid;
    if (schoolGuid) {
      yield put(usersActions.fetchSchoolUsers(schoolGuid));
    }
  });

  yield takeLatest(actionTypes.UserEdit, function* userEdit(action) {
    const {
      data: { data: user },
    } = yield editUser(action.payload.user.guid, action.payload.user.name, action.payload.user.lastname, action.payload.user.email);
    yield put(actions.editUserReducer(user));
  });

  yield takeLatest(actionTypes.UserEditProfile, function* UserEditProfile(action) {
    try {
      const {
        data: { data: user },
      } = yield editProfile(action.payload.user);
      yield put(actions.editProfileSuccess(user));
    } catch (error) {
      yield put(actions.editProfileError(error));
    }
  });

  yield takeLatest(actionTypes.EditAvatar, function* userEdit() {
    yield put(actions.editProfileSuccess({}));
  });
}
